import { TrendingUpRounded } from "@material-ui/icons"

export const staticDailyprizes = [
  "FRIDAY AUGUST 7 - Eternal Happy Hour at The Scratch",
  "SATURDAY AUGUST 8 - The Pink Hotel 2 Night Getaway",
  "SUNDAY AUGUST 9 - $500 Vault Games Voucher",
  "MONDAY AUGUST 10 - SoBa Online gym pass & Open House West End voucher",
  "TUESDAY AUGUST 11 - Year of film & digital development from Fotofast",
  "WEDNESDAY AUGUST 12 - 20 car passes for Yatala Drive In",
  "THURSDAY AUGUST 13 - $500 tattoo credit from Oracle Tattoo",
  "FRIDAY AUGUST 14 - Year of beer from Newstead Brewery",
  "SATURDAY AUGUST 15 - $500 skateboard & store credit from Parliament",
  "SUNDAY AUGUST 16 - Sub Discount Outlet Prize Pack"
]

export const staticGoldprizes = [
  {
    general: true,
    super: false,
    under18: true,
    household: true,
    business: true,
    band: true,
    soloartist: true,
    prize: "No prize thanks, just that warm inner glow",
  },
  {
    general: true,
    super: false,
    under18: false,
    household: false,
    business: false,
    band: false,
    soloartist: false,
    prize: "Custom Netherworld Games Cabinet & Goodies",
  },

  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "The Brightside Golden Ticket (18+)",
  // },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "The Tivoli Golden Ticket (18+)",
  // },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "The Princess Golden Ticket (18+)",
  // },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "The Triffid Golden Ticket (18+)",
  // },
  {
    general: true,
    super: false,
    under18: false,
    household: false,
    business: false,
    band: false,
    soloartist: false,
    prize: "Five Star Cinemas Golden Ticket",
  },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "All My Friends Year of Groceries",
  // },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "Downes Shoes Custom Radiothon Docs",
  // },
  {
    general: true,
    super: false,
    under18: false,
    household: false,
    business: false,
    band: false,
    soloartist: false,
    prize: "Felons Year of Beer (18+)",
  },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "West End Electric VIP Package (18+)",
  // },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "BIGSOUND Showcase Double Pass (18+)",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: true,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "Mannys Music Electric Guitar Pack",
  // },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "Store DJ DJ Controller Pack",
  // },
  // {
  //   general: false,
  //   super: true,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "BrisFest Art Posse Package",
  // },
  // {
  //   general: true,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "Banshees Golden Ticket (18+)",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: true,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "Downes Shoes Kids/Youth Docs",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   pet: true,
  //   prize: "Downes Shoes Pet Collar",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: true,
  //   prize: "Wrecker Locally-made Guitar or Bass",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: false,
  //   soloartist: true,
  //   prize: "BIGSOUND Delegate Pass",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: true,
  //   soloartist: false,
  //   prize: "BIGSOUND Delegate Pass + Showcase Pass Pack",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: true,
  //   soloartist: true,
  //   prize: "Red Octopus T-shirt Run",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: true,
  //   soloartist: true,
  //   prize: "Suitcase Records Vinyl Pressing",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: true,
  //   band: true,
  //   soloartist: true,
  //   prize: "AS Accounting Services",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: true,
  //   business: false,
  //   band: false,
  //   soloartist: false,
  //   prize: "4ZZZ Music & Merch Pack",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: false,
  //   band: true,
  //   soloartist: true,
  //   prize: "MBE Brisbane CBD Musician's Print Pack",
  // },
  // {
  //   general: false,
  //   super: false,
  //   under18: false,
  //   household: false,
  //   business: true,
  //   band: false,
  //   soloartist: false,
  //   prize: "MBE Brisbane CBD Business Print & Address Pack",
  // },
]

export const staticRadiothonPrizes = []
// export const staticRadiothonPrizes = [
//   {
//     general: true,
//     super: false,
//     under18: true,
//     household: true,
//     business: true,
//     band: true,
//     soloartist: true,
//     prize: "No prize thanks, just that warm inner glow",
//   },
//   // {
//   //   general: false,
//   //   super: true,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Young Henrys Super Sub Pack!",
//   // },
  
//   // {
//   //   general: false,
//   //   super: true,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Young Henrys Super Sub Pack!",
//   // },
//   // {
//   //   general: false,
//   //   super: true,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Brisbane Powerhouse mega prize pack",
//   // },
//   // {
//   //   general: false,
//   //   super: true,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "The Triffid Golden Ticket",
//   // },
//   // {
//   //   general: false,
//   //   super: true,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Sonic Sherpa $300 Gift Voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: true,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "The Tivoli + Princess Theatre Golden Ticket",
//   // },
//   {
//     general: true,
//     super: false,
//     under18: false,
//     household: false,
//     business: false,
//     band: false,
//     soloartist: false,
//     prize: "Jungle Love Double Pass (18+)",
//   },
//   {
//     general: true,
//     super: false,
//     under18: false,
//     household: false,
//     business: false,
//     band: false,
//     soloartist: false,
//     prize: "Young Henrys Adventurous Bars (18+)",
//   },
//   {
//     general: true,
//     super: false,
//     under18: false,
//     household: false,
//     business: false,
//     band: false,
//     soloartist: false,
//     prize: "SOBAH Annual Subscription + Merch Pack (18+)",
//   },
//   {
//     general: true,
//     super: false,
//     under18: true,
//     household: false,
//     business: false,
//     band: false,
//     soloartist: false,
//     prize: "Nura Headphone Pack",
//   },
//   {
//     general: true,
//     super: false,
//     under18: true,
//     household: false,
//     business: false,
//     band: true,
//     soloartist: true,
//     prize: "Queensland Theatre Season Ticket (2023 Season)",
//   },
//   {
//     general: true,
//     super: false,
//     under18: true,
//     household: false,
//     business: false,
//     band: false,
//     soloartist: true,
//     prize: "Renag eBike 6 months Hire",
//   },
//   {
//     general: true,
//     super: false,
//     under18: true,
//     household: false,
//     business: false,
//     band: false,
//     soloartist: false,
//     prize: "Renag Custom ebike Battery",
//   },
//   {
//     general: false,
//     super: false,
//     under18: true,
//     household: false,
//     business: false,
//     band: false,
//     soloartist: false,
//     prize: "Downes Shoes Kids' Docs",
//   },

//   // {
//   //   general: true,
//   //   super: true,
//   //   under18: true,
//   //   household: true,
//   //   business: true,
//   //   band: true,
//   //   soloartist: true,
//   //   prize: "4ZZZ Music + Merch Pack",
//   // },

//   {
//     general: false,
//     super: false,
//     under18: false,
//     household: false,
//     business: false,
//     band: true,
//     soloartist: true,
//     prize: "Suitcase Records Vinyl Pressing",
//   },
//   {
//     general: false,
//     super: false,
//     under18: false,
//     household: false,
//     business: true,
//     band: true,
//     soloartist: true,
//     prize: "$1200 AS Accounting Voucher",
//   },
//   {
//     general: false,
//     super: false,
//     under18: false,
//     household: false,
//     business: false,
//     band: true,
//     soloartist: true,
//     prize: "The Outpost Venue Hire",
//   },
//   {
//     general: false,
//     super: false,
//     under18: false,
//     household: false,
//     business: false,
//     band: true,
//     soloartist: true,
//     prize: "MBE CBD Musician's Print Pack",
//   },
//   {
//     general: false,
//     super: false,
//     under18: false,
//     household: false,
//     business: false,
//     band: true,
//     soloartist: true,
//     prize: "Red Octopus Custom T-shirt Run",
//   },
//   {
//     general: false,
//     super: false,
//     under18: false,
//     household: false,
//     business: true,
//     band: false,
//     soloartist: false,
//     prize: "MBE CBD Business Print & Address Pack",
//   },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: true,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Oche experience for 6",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: true,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Yatala Drive In Theatre passes",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Signal Chain pedal pack",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "NO.ONE NETWORK $400 'top to toe' merch pack",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Dendy Cinema Gold Card",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Netherworld Arcade Mega Pack",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Young Henrys Year of Beer",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Vault Games $500 voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Coffee Supreme Home Brew Kit",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Yonder Festival Double Pass + Car Pass",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Banshees Bar $100 Voucher",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Lil J's Vintage Stallholder Pass",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Fortitude Music Hall tickets + $200 tab",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Big Fork Comedy 6 week comedy masterclass",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Avid Reader Ultimate Book Bundle",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: true,
//   //   prize: "Hair Nerd Salon haircut + merch pack",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Presents of Mind $300 voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: true,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Presents of Mind $200 voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Surf Connect standup paddle board lessons",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Brisbane Festival 'Auntie's Fiafia Night' tickets",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Twisted Pair Productions 'Party Pack' hire",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Books@Stones $50 Voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Yatala Drive In Theatre passes",
//   // },
//   // {
//   //   general: true,
//   //   super: false,
//   //   under18: false,
//   //   household: true,
//   //   business: false,
//   //   band: true,
//   //   soloartist: false,
//   //   prize: "GATORBEUG water pourer",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: true,
//   //   household: false,
//   //   business: true,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Mappins Nursery $100 Voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: false,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: true,
//   //   prize: "Downes Shoes $200 Voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: true,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Downes Shoes $200 Voucher",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: true,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Yatala Drive In Theatre passes",
//   // },
//   // {
//   //   general: false,
//   //   super: false,
//   //   under18: true,
//   //   household: false,
//   //   business: false,
//   //   band: false,
//   //   soloartist: false,
//   //   prize: "Surf Connect standup paddle board lessons",
//   // },
// ]

// export const staticGoldprizes = [
// 	{
//     general: true,
//     super: true,
//     under18: true,
//     household: true,
//     business: false,
//     band: true,
//     pet: false,
//     soloartist: true,
//     prize: "",
//   },
//   // {
//   //   general: true,
//   //   super: true,
//   //   under18: true,
//   //   household: true,
//   //   business: false,
//   //   band: true,
//   //   pet: false,
//   //   soloartist: true,
//   //   prize: "Netherworld Game Cabinet",
//   // },
//   // {
//   //   general: true,
//   //   super: true,
//   //   under18: true,
//   //   household: true,
//   //   business: false,
//   //   band: true,
//   //   pet: false,
//   //   soloartist: true,
//   //   prize: "Dendy Cinemas Gold Card",
//   // },
//   {
//     general: true,
//     super: true,
//     under18: false,
//     household: true,
//     business: false,
//     band: true,
//     pet: false,
//     soloartist: true,
//     prize: "Young Henrys Year of Adventurous Beer (18+)",
//   },
//   // {
//   //   general: true,
//   //   super: true,
//   //   under18: true,
//   //   household: true,
//   //   business: false,
//   //   band: true,
//   //   pet: false,
//   //   soloartist: true,
//   //   prize: "Urban Climb Annual Membership",
//   // },
//   {
//     general: true,
//     super: true,
//     under18: false,
//     household: true,
//     business: false,
//     band: true,
//     pet: false,
//     soloartist: true,
//     prize: "The Triffid Golden Ticket (18+)",
//   },
//   // {
//   //   general: true,
//   //   super: true,
//   //   under18: true,
//   //   household: true,
//   //   business: false,
//   //   band: true,
//   //   pet: false,
//   //   soloartist: true,
//   //   prize: "Downes Shoes Custom Docs",
//   // },
//   {
//     general: true,
//     super: true,
//     under18: true,
//     household: true,
//     business: false,
//     band: true,
//     pet: false,
//     soloartist: true,
//     prize: "All My Friends Year of Groceries",
//   },
//   {
//     general: true,
//     super: false,
//     under18: true,
//     household: true,
//     business: false,
//     band: true,
//     pet: false,
//     soloartist: true,
//     prize: "No prize thanks, just that warm inner glow",
//   },
// ]

// export const staticAprilAtonementPrizes = [
// 	"",
//   "Jungle Love Festival Double Pass + Bus Package (18+)",
//   "Dendy Cinemas Golden Ticket",
//   "$666 Tradition Tattoo Voucher (18+)",
//   "$350 SWOP West End Voucher",
//   "Young Henrys Beer + Merch pack (18+)",
//   "4ZZZ Music + Merch Pack",
//   "Queensland Theatre Season Pass",
//   "Coochiemudlo Island Fest Family Pass",
//   "All My Friends Picnic + Pickle Pack",
//   "The Triffid Psych Night Party Pack",
//   "No prize thanks, just that warm inner glow"
// ]
// export const staticAprilAtonementPrizes = [
// 	"",
//   "Jungle Love Double Pass (18+ only)",
//   "Mountain Goat Beer + Merch Pack (18+ only)",
//   "A Year of SOBAH non-alcoholic beer (18+ only)",
//   "Big Fork Golden Ticket",
//   "$500 Oracle Tattoo Voucher (18+ only)",
//   "$300 SWOP Voucher",
//   "4ZZZ Music and Merch Pack",
//   "Brisbane Writers Festival 2022 Golden Ticket",
//   "Dendy Cinemas Silver Card",
//   // "Gatorbeug 4/20 surprise prize (18+ only)",
//   "No prize thanks, just that warm inner glow"
// ]


// export const staticAprilAtonementPrizes = [
// 	"",
//   // "Gatorbeug 4/20 surprise prize (18+)",
//   "Dendy Gold Card",
//   "The Zoo Golden Ticket (18+)",
//   "Felons Brewing Co Experience (18+)",
//   "Nura Headphone Pack",
//   "Urban Climb Membership",
//   "Brisbane Writers Festival 2023 Golden Ticket",
//   "No prize thanks, just that warm inner glow"
// ]

// export const staticAprilAtonementPrizes = [
//   "New Farm Cinemas Mid-Year Retrospective Pass",
//   "Big Fork Golden Ticket",
//   "Felons Brewing Co Experience for 6 (18+)",
//   "BrisFest VIP Riverfire Package (18+)",
//   "Jetts Fortitude Valley Membership",
//   "Oracle Tattoo Voucher Pack (18+)",
//   // "Woods Music Electric Guitar Pack (U18)",
//   // "4ZZZ Poster Bandana + Grid Patch",
//   // "VENTspace Venue Hire and Shoot",
//   // "Art From The Margins Micro Gallery",
//   // "4000 Records x 4ZZZ Music and Merch Pack",
//   // "Gatorbeug 4/20 Pack",
//   "No prize thanks, just that warm inner glow"
// ]

export const staticAprilAtonementPrizes = [
  "New Farm Cinemas Mid-Year Retrospective Pass",
  "BEMAC Golden Ticket",
  "Vikasati - Double 6 month Balance membership",
  "Oasis VIP Deluxe Experience (18+)",
  "Stork Furniture LP Cool Jay Record Console",
  "BrisFest VIP Riverfire Package (18+)",
  "Heaps Normal Year of Alcohol-Free Beer (18+)",
  "Jetts Fortitude Valley Annual Membership",
  // "The Buttery Music School Lesson Package",
  // "Outside the Box Framed Pet Portrait",
  // "GYROSTREAM + Smoked Garage Ultimate Release Package",
  // "Vanilla Zulu Cooking Classes",
  // "Gatorbeug 4/20 Pack",
  "No prize thanks, just that warm inner glow"
]

export const staticMonthlyPrizes = [
	"",
  "Enter me in the monthly sub prize draw!",
  "No prize thanks, just that warm inner glow"
]
